.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  font-size: 19px;
  line-height: 1.47368421;
  background-color: #711C2F;
  cursor: pointer;
  color: #fff;
  padding: 10px 46px 10px 14px;
  -webkit-appearance: none;
  border-radius: 0;
  border: none;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  background-size: 18px 18px;
  background-position: right 14px center;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-white.svg);
  transition: all 0.3s;
}
.button:hover,
.button:focus {
  color: #711C2F;
  background-color: #D1E0B1;
  background-image: url(/images/arrow-right-red.svg);
}
.button:before {
  display: none;
}
.desk {
  border-width: 0 40px;
}
#head {
  margin-bottom: -8px;
}
.section--red {
  margin-top: 70px;
}
.section--three .base {
  margin-top: 16px;
  margin-bottom: 16px;
}
.section--four .area .unit.slim {
  margin-bottom: 32px;
}
.section--one .area {
  width: 100%;
}
.section--one .area > .unit {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.section--one .area .cb-album .body,
.section--one .area .head,
.section--one .area .foot,
.section--one .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim {
  width: 46%;
}
.section--one .area > .slim .head,
.section--one .area > .slim .foot,
.section--one .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim .tiny {
  width: 100%;
}
.section--one .area {
  width: 104%;
  margin-left: -2%;
}
.section--three .side {
  width: 64%;
}
.section--three .base {
  float: right;
  width: 32%;
}
.section--one .area .pure.wide .part.tiny {
  width: 48%;
}
.section--one .area .flat {
  min-height: 314px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 96%;
}
@media (max-width: 1024px) {
  .section--one .area .flat {
    min-height: 280px;
  }
}
.section--one .area .flat .body {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.section--one .area .flat .pict.tall:first-child {
  position: absolute;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%);
  flex: 0;
  margin: 0;
  height: 314px;
  width: 42%;
}
.section--one .area .flat .pict.tall:first-child * {
  height: 100%;
}
.section--one .area .flat .pict.tall:first-child img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.section--one .area .flat .pict.tall:first-child img {
  object-fit: contain;
}
@media (max-width: 1024px) {
  .section--one .area .flat .pict.tall:first-child {
    height: 280px;
  }
}
.section--one .area .flat.wide .body {
  padding-left: 46%;
}
.section--one .area .flat.wide .pict.tall:first-child {
  left: 0;
}
.section--one .area .flat.wide .pict.tall:first-child img {
  object-position: left center;
  font-family: 'object-fit: contain; object-position: 0 50%;';
}
.section--one .area .flat.slim .body {
  padding-right: 46%;
}
.section--one .area .flat.slim .pict.tall:first-child {
  right: 0;
}
.section--one .area .flat.slim .pict.tall:first-child img {
  object-position: right center;
  font-family: 'object-fit: contain; object-position: 100% 50%;';
}
/*# sourceMappingURL=./screen-medium.css.map */